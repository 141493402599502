import { CanvasWrapper } from '@isaac_ua/drei-html-fix';
import { isSafari } from 'react-device-detect';
import { useEffect } from 'react';

export default function CanvasWrapperSafari({ children }) {
  useEffect(() => {
    if (isSafari) {
      let resizeTimer;
      const handleResize = () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          window.location.reload();
        }, 250); // Debounce the reload to prevent multiple reloads
      };

      window.addEventListener('resize', handleResize);
      
      return () => {
        window.removeEventListener('resize', handleResize);
        clearTimeout(resizeTimer);
      };
    }
  }, []);

  return isSafari ? (
    <CanvasWrapper>
      {children}
    </CanvasWrapper>
  ) : (
    <>{children}</>
  );
}